.Loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #fff;
}
.loading-backdrop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-color: #fff;
}
.loading-logo {
    width: 400px;
    margin: 0 auto;
    display: block;
}
