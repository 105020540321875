.plans {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: flex-start;
    align-items: center;
}
.toggle {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}
stripe-pricing-table {
    width: 100%;
}

.toggle .yearly {
    border-radius: 10px !important;
}
.toggle .monthly {
    border-radius: 10px !important;
}
.toggle .monthly,
.toggle .yearly {
    font-weight: 700 !important;
    padding: 0.5rem 2rem !important;
}

.toggle .yearly.active {
    background-color: #ffc107 !important;
    color: #000 !important;
}
.toggle .monthly.active {
    background-color: #ffc107 !important;
    color: #0a0505 !important;
}

.manage-container {
    /* border: 2px solid orange; */
    max-width: 100%;
    margin: 0 auto;
    text-align: left;
}
.manage-container .passed-btn {
    margin-top: 2rem;
    margin-bottom: 1rem;
}
.data-container {
    display: flex;
}

.Subscriptions h1 {
    text-align: left;
}

.Subscriptions {
    margin-top: 4rem;
}
