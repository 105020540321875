.account-details {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
    width: 100%;
}

.user-info {
    display: flex;
    flex-direction: column;
    align-self: center;
}
