.tab_text {
    font-size: 16px !important;
    font-weight: bolder;
}
.scanHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.chipHolder {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
