.legend {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    margin: 1rem 0;
}

.labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.gradient {
    height: 10px;
    width: 100%;
    border-radius: 10px;
    margin-top: 5px;
}
.labels span {
    font-weight: 700;
}
