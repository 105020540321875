.mui-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* border: 2px solid blue; */
}

.robot-app {
    max-width: 15rem;
    height: auto;
    /* mix-blend-mode: screen !important; */
}
