:root {
    --strength: 0;
}

.Results {
    width: 100%;
    max-height: 500px;
    min-height: 500px;
    overflow-y: auto;
    padding: 1rem;
    border-radius: 10px;
    font-size: 1rem;
    background-color: #fff;
    border: 1px solid #000;
    font-family: Arial, Helvetica, sans-serif;
}
.Results-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: center;
    width: 100%;
}
.switchContainer {
    display: flex;
    justify-content: flex-end;
}
.highlight.red {
    background: rgba(211, 47, 47, var(--strength));
    color: #fff;
}
.red {
    color: #000;
}
.highlight.green {
    background: rgba(79, 180, 119, calc(1 - var(--strength)));
}
.highlight.pink {
    background: rgba(211, 47, 47, var(--strength));
}
.block {
    display: inline;
}

.line {
    margin: 0;
    padding: 0;
    white-space: pre-wrap;
}
.spacer {
    display: inline-block;
    width: 1ch;
}
