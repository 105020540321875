.modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    min-width: 360px;
    background-color: white;
    box-shadow: 24;
    border-radius: 10px;
}

#modal-title {
    padding: 1.5rem;
}

#modal-text {
    padding: 0rem 1.5rem;
}
#modal-inputs {
    padding: 0rem 1.5rem;
    width: 100%;
}
#modal-inputs div {
    width: 100%;
}
.modal-buttons {
    display: flex;
    justify-content: flex-end;
}
