.side-nav-link {
    display: block;
    color: #1f2641;
    text-decoration: none;
    font-size: 1.25rem;
    font-weight: 500;
    transition: all 0.2s ease-in-out;
    /* border: 1px solid red; */
}

.disabled-link {
    color: #808080;
    pointer-events: none;
}

.side-nav-link:hover {
    background: white !important;
    background-color: #ffe492 !important;
}
.side-nav-link.active {
    background-color: #ffc107;

    /* background-color: #fee140;
    background-image: linear-gradient(90deg, #ffff 0%, #fa709a 100%); */

    /* border: 1px dashed black; */
}

.header-logo {
    margin-right: 10px;
    width: 80%;
    height: 80%;
    padding-top: 4px;
    /* border: 1px solid green; */
}

.nav-toolbar {
    display: flex;
    justify-content: space-between;
    /* border: 1px solid orange; */
}
.creditsChip {
    margin-right: 2rem;
    text-decoration: none;
}
