.checklist-card {
    width: 100%;
    border-radius: 10px !important;
    padding: 0.5rem;
    margin: 2rem 0;
    background-color: #f7f8f9 !important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1) !important;
    transition: all 0.2s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 2px solid blue; */
}
.checklist-card-content {
    padding-bottom: 1rem !important;
}
.checklist-card-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}
.checklist-text {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 1rem;
    color: #1f2641;
}

.checklist-card.green {
    background-color: #4fb477 !important;
    box-shadow: none !important;
}
.checklist-card.green .checklist-text,
.checklist-card.green svg {
    color: #000;
}
.checklist-item-disabled {
    pointer-events: none;
}
