.results-bar-container {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    height: 70px;
    width: 100%;
    border-radius: 0.5rem;
    border: 1px solid #e1e1e1;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    margin-bottom: 1.5rem;
    padding: 0;
    cursor: pointer;
}
.results-bar-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 700;
    padding-bottom: 0.5rem;
}
.results-bar-expand-button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.results-bar {
    height: 100%;
    border-radius: 0.5rem 0 0 0.5rem;
}
.results-bar.full {
    border-radius: 0.5rem;
}
.results-bar-val {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    padding: 0.5rem;
    font-size: 2rem;
    font-weight: 800;
    color: #fff;
    max-width: 100%;
}
.results-bar-val.inside {
    justify-content: flex-end;
}
.results-bar-val.outside {
    justify-content: flex-start;
    color: black;
}
.results-bar-details {
    margin-bottom: 2rem;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s ease-in-out;
}

.results-bar-details.expanded {
    max-height: 1000px; /* Adjust this value based on the maximum content height */
}
