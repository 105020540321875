.Welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #f0f0f0;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
    /* margin: 0 0 1.5rem; */
    padding: 1.5rem;
    /* width: 70%; */
}
