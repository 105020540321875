.App {
    font-family: 'Open Sans', sans-serif;
}
p {
    line-height: 1.25;
}
p.errorText {
    color: #f00;
}
.passed-btn {
    display: flex !important;
    align-items: center !important;
    gap: 1rem !important;
    font-family: 'Open Sans', sans-serif;
    text-align: center !important;
    background-color: #ffc107;
    color: #1f2641;
    font-size: 1rem !important;
    font-weight: 700 !important;
    text-transform: none !important;
    border-radius: 10px !important;
    padding: 15px 30px !important;
    letter-spacing: 0.03em !important;
    cursor: pointer !important;
}
.passed-btn.full-width {
    width: 100% !important;
}
.passed-btn.centered {
    margin: 0 auto;
}
.passed-btn.iconed {
    gap: 0 !important;
}
/* .login {
    background-color: #1f2641;
    color: #fff;
} */
.login-blurb {
    font-size: 1.5em;
    font-weight: 600;
    text-align: center;
    margin: 2rem 1rem;
    max-width: 768px;
}

.logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    width: 500px;
    max-width: 90%;
    color: white;
    height: 100vh;
}

.logo-container.dark {
    color: #1f2641;
}
.login-logo {
    width: 100%;
    max-width: 500px;
}

.header-logo {
    width: 100%;
    /* border: 1px solid green; */
}

.drawer-footer {
    position: fixed !important;
    bottom: 0;
    padding-bottom: 10px !important;
}
.footer__user__image {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
}
