.contentBox {
    width: 100%;
    padding: 1rem;
    border-radius: 0.25rem;
    font-size: 1rem;
    white-space: pre-line;
    background-color: #fff;
    resize: none;
}
.engWarning {
    color: #f00;
    font-size: 0.75rem;
    margin-top: 0.5rem;
    font-style: italic;
}
