.affiliate-container {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.affiliate-card {
    width: 100% !important;
    border-radius: 10px !important;
    margin: 2rem 0 !important;
    padding: 0 1rem !important;
    background-color: #f0f0f0;
}

.affiliate-card-title {
    text-align: center;
}

.affiliate-container h1 {
    text-align: center;
}
