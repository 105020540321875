.helper-button {
    background-color: transparent;
    border: none;
    color: #1f2641;
    outline: none;
    text-align: left;
    cursor: pointer;
}

.helper-text {
    color: rgba(0, 0, 0, 0.6);
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin: 3px 14px 0px;
}
.no-indent {
    margin: 0px;
}
.dropdown-edit-button {
    background-color: transparent;
    border: none;
    color: #1f2641;
    outline: none;
    cursor: pointer;
}
.dropdown-edit-button:hover {
    text-decoration: underline;
}
