.drop-zone {
    background-color: #f8f9fa;
    border: 2px dashed #e9ecef;
    border-radius: 0.25rem;
    color: #868e96;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 1rem;
    text-align: center;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin-bottom: 1rem;
    cursor: pointer;
}
.drop-zone.active {
    border-color: #4fb477;
}
